<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.send"
        @click="clickEnviarSobre"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SobreViewData'
import { SOBRE } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'tabla_descripcion',
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idsobre
        item.title = this.$online.sobre.title(item)
        item.subtitle = this.$online.sobre.subtitle(item)
        const badge = this.$online.sobre.badge(item)
        item.badge = badge.badge
        item.badgeColor = badge.badgeColor
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = 'Sobre de documentación'
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectSobre(
          this, this.routeParams.idsobre
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idsobre,
      )
      // documento
      const detailSobreDocumento = this.addDetail(
        'sobreDocumento', 'Documentos', 'Documentos incluidos en el sobre', 'documentacionEnviar'
      )
      detailSobreDocumento.badge = resp.data.selectSobreDocumento.result.dataset[0].count || 0
      // destinatarios
      const detailSobreDestinatario = this.addDetail(
        'sobreDestinatario', 'Destinatarios', 'Destinatarios asignados para el envío', 'personaContacto'
      )
      detailSobreDestinatario.badge = resp.data.selectSobreDestinatario.result.dataset[0].count || 0
      // destinatariosFaltan
      if (this.item.dataset.estado === SOBRE.estados.abierto) {
        const detailSobreDestinatarioFaltan = this.addDetail(
          'sobreDestinatarioFaltan', 'Destinatarios previstos', 'Destinatarios previstos para el envío', 'personaContacto'
        )
        detailSobreDestinatarioFaltan.badge = resp.data.selectDestinatariosFaltan.result.dataset[0].count || 0
      }
      // envíos
      const detailSobreEnvio = this.addDetail(
        'sobreEnvio', 'Envios', 'Envíos realizados para el sobre', 'send'
      )
      detailSobreEnvio.badge = resp.data.selectSobreEnvio.result.dataset[0].count || 0
    },
    clickDetail (data) {
      if (data.detail.name === 'sobreDocumento') {
        this.$appRouter.push({
          name: 'sobres__documentos__sobre-documento-list',
          params: {
            id: this.routeParams.id,
            idtabla: this.routeParams.idtabla,
            idsobre: this.routeParams.idsobre,
          },
        })
      } else if (data.detail.name === 'sobreDestinatario') {
        this.$appRouter.push({
          name: 'sobres__destinatarios__sobre-destinatario-list',
          params: {
            id: this.routeParams.id,
            idtabla: this.routeParams.idtabla,
            idsobre: this.routeParams.idsobre,
          },
        })
      } else if (data.detail.name === 'sobreDestinatarioFaltan') {
        this.$appRouter.push({
          name: 'sobres__destinatarios__sobre-destinatario-faltan-list',
          params: {
            id: this.routeParams.id,
            idtabla: this.routeParams.idtabla,
            idsobre: this.routeParams.idsobre,
          },
        })
      } else if (data.detail.name === 'sobreEnvio') {
        this.$appRouter.push({
          name: 'sobres__envios__sobre-envio-list',
          params: {
            id: this.routeParams.id,
            idtabla: this.routeParams.idtabla,
            idsobre: this.routeParams.idsobre,
          },
        })
      }
    },
    clickEnviarSobre () {
      this.$appRouter.push({
        name: 'sobres__presupuestos-sobre-enviar',
        params: {
          id: this.routeParams.id,
          idtabla: this.routeParams.idtabla,
          idsobre: this.routeParams.idsobre,
        },
      })
    },
  },
}
</script>
